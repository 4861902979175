import { ListItem, ListItemText } from "@mui/material"
import PropTypes from "prop-types"
// import { createTheme } from "@mui/material/styles";

// const miTheme = createTheme({
//   components: {
//     MuiListItem: {
//       styleOverrides: {
//         root: {
//           "&.Mui-selected": {
//             backgroundColor: "#FCE7E6",
//             color: "#e1251b",

//             "&:hover": {
//               backgroundColor: "#FEF3F3",
//             },
//           },
//           "&:hover": {
//             color: "#e1251b",
//             backgroundColor: "#FEF3F3",
//           },
//           borderRadius: 5,
//         },
//       },
//     },
//     MuiListItemIcon: {
//       styleOverrides: {
//         root: {
//           "&.Mui-selected": {
//             color: "#e1251b",
//           },
//         },
//       },
//     },
//   },
// });

export default function SidebarListItems(props) {
  const { menuTitle, items, selectedItem, handleClick } = props

  return Object.keys(items).map((itemTitle) => {
    const item = items[itemTitle]
    return (
      <ListItem
        sx={{ mb: 1 }}
        key={"sidebarList" + menuTitle + "Item" + itemTitle}
        button
        onClick={() => handleClick(menuTitle + itemTitle, item.route)}
        selected={selectedItem === menuTitle + itemTitle}
      >
        {item.icon}
        <ListItemText sx={{ ml: 2 }} primary={itemTitle} />
      </ListItem>
    )
  })
}

SidebarListItems.propTypes = {
  menuTitle: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}
