import { TableBody } from "@mui/material"
import OrderRow from "./OrderRow"
import OrderDetailsRow from "./OrderDetailsRow"
import { getComparator } from "../../utils/filter"

export default function OrdersBodyTable(props) {
  const { orders, openCollapse, setOpenCollapse, orderBy, sortBy, page, rowsPerPage } = props

  const handleCollapse = (open, index) => {
    let newCollapseValues = openCollapse.map((el) => el)
    newCollapseValues[index] = open
    setOpenCollapse(newCollapseValues)
  }

  return (
    <TableBody>
      {orders
        .sort(getComparator(orderBy, sortBy))
        .slice((page + 1) * rowsPerPage - rowsPerPage, (page + 1) * rowsPerPage)
        .map((order, index) => [
          <OrderRow
            key={"order_" + order.number}
            order={order}
            index={index}
            handleCollapse={handleCollapse}
            openCollapse={openCollapse}
          />,
          <OrderDetailsRow
            key={"orderDetails_" + order.number}
            order={order}
            index={index}
            openCollapse={openCollapse}
          />,
        ])}
    </TableBody>
  )
}
