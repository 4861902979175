import { CardHeader, InputAdornment, TextField } from "@mui/material"
import { Search } from "@mui/icons-material"

export default function OrdersCardHeader({searchInput, setSearchInput}) {
  

  return (
    <CardHeader
      sx={{ p: 3 }}
      titleTypographyProps={{
        noWrap: true, sx: {
          px: 2,
          backgroundColor: "primary.bg",
          height: "100%",
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          color: "primary.main"
        }
      }}
      title={"Liste des bons de commande"}
      action={(<TextField
          // size="small"
          fullWidth
          id="OrdersFinderInput"
          placeholder="Rechercher un bon"
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          color="primary"
          sx={{ alignItems: "center" }}
          InputProps={{
            startAdornment: (<InputAdornment position="start">
              <Search />
            </InputAdornment>), sx: { borderRadius: 2 }
          }}
        />

      )}
    />
  )
}