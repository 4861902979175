import { useQuery } from "react-query"
import apiClient from "../../services/axios.config"
import { useState } from "react"
import OrdersTableContainer from "./OrdersTableContainer"
import { useKeycloak } from "@react-keycloak/web"
import LoadingBody from "./LoadingBody"
import ErrorBody from "./ErrorBody"
import EmptyTable from "./EmptyTable"
import OrdersBodyTable from "./OrdersBodyTable"
import TablePaginationSR from "./TablePaginationSR"

export default function OrdersTable() {
  const [orders, setOrders] = useState([])
  const [openCollapse, setOpenCollapse] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handlePageChange = (newPage) => {
    setPage(newPage)
    setOpenCollapse((prev) => prev.map(() => false))
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
    setOpenCollapse((prev) => prev.map(() => false))
  }

  const config = {
    headers: { Authorization: "Bearer " + useKeycloak().keycloak.token },
  }
  const getOrders = useQuery(
    "getOrders",
    async () => {
      return await apiClient.get(`/purchase-orders?orderBy=orderId&sortBy=desc`, config)
    },
    {
      refetchOnWindowFocus: false,
      enabled: true, // eslint-disable-next-line no-unused-vars
      onSuccess: (res) => {
        const { docs } = res.data
        setOpenCollapse(Array(docs.length).fill(false))
        setOrders(
          docs.map((order) => {
            return {
              id: order._id,
              number: `E-${order.orderId}-${order.creatorTla}`,
              applicant: order.createdBy.name,
              supplierName: order.supplier.name,
              supplierAddress: order.supplier.address,
              orderedAt: order.orderedAt,
            }
          })
        )
      },
      onError: (err) => {
        setOrders([])
        console.log(`getOrders query error : ` + JSON.stringify(err))
      },
    }
  )

  return (
    <OrdersTableContainer
      paginationComponent={
        <TablePaginationSR
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      }
      orders={orders}
    >
      {getOrders.isLoading ? (
        <LoadingBody />
      ) : getOrders.isError ? (
        <ErrorBody />
      ) : orders.length === 0 ? (
        <EmptyTable />
      ) : (
        <OrdersBodyTable
          openCollapse={openCollapse}
          setOpenCollapse={setOpenCollapse}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      )}
    </OrdersTableContainer>
  )
}
