import { List, ListSubheader } from "@mui/material"
import PropTypes from "prop-types"
import SidebarListItems from "./SidebarListItems"

export default function SidebarList(props) {
  const { menus, selectedItem, handleClick } = props

  return Object.keys(menus).map((menuTitle) => {
    const items = menus[menuTitle]
    return (
      <List
        key={"sidebarList" + menuTitle}
        sx={{ mx: 2 }}
        data-cy={"sidebarList" + menuTitle}
        aria-labelledby={"sidebarList" + menuTitle}
        subheader={
          <ListSubheader
            color="primary"
            style={{ textAlign: "left", fontWeight: "500", color: "black" }}
            id={"sidebarList" + menuTitle}
          >
            {menuTitle.toUpperCase()}
          </ListSubheader>
        }
      >
        <SidebarListItems
          menuTitle={menuTitle}
          items={items}
          selectedItem={selectedItem}
          handleClick={handleClick}
        />
      </List>
    )
  })
}

SidebarList.propTypes = {
  menus: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}
