import { IconButton, TableCell, TableRow } from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"

export default function OrderRow({ order, index, openCollapse, handleCollapse }) {
  const displayDate = () =>
    new Date(order.orderedAt).toLocaleDateString("fr-CH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })

  return (
    <TableRow
      id={"order_" + order.number}
      sx={{
        "&> td": {
          fontSize: "1rem",
          fontWeight: 500,
          whiteSpace: "nowrap",
          overflow: "clip",
        },
        "&:hover> td": { backgroundColor: "#e3f2fd !important", overflow: "auto" },
        "&> td:first-of-type": { borderRadius: `8px 0 0 8px` },
        "&> td:last-of-type": { borderRadius: `0 8px 8px 0` },
      }}
      hover
      onClick={() => handleCollapse(!openCollapse[index], index)}
    >
      <TableCell>
        <IconButton
          size="small"
          color="primary"
          sx={{ padding: 0 }}
          onClick={() => handleCollapse(!openCollapse[index], index)}
        >
          {openCollapse[index] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      </TableCell>
      <TableCell>{order.number}</TableCell>
      <TableCell>{order.applicant}</TableCell>
      <TableCell>{order.supplierName}</TableCell>
      <TableCell>{displayDate()}</TableCell>
    </TableRow>
  )
}
