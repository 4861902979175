import { useState } from "react"
import { Avatar, IconButton, Menu, MenuItem, Tooltip, Typography, Box } from "@mui/material"
import { useKeycloak } from "@react-keycloak/web"

/**
 * Component AuthToolbarItem
 *
 * Handle login / logout functionnalities with a user button and a menu
 */
export default function AuthToolbarItem() {
  const { keycloak } = useKeycloak()
  const [anchorElUser, setAnchorElUser] = useState(null)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogOut = () => {
    keycloak.logout({ redirectUri: window.location.origin })
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        {keycloak.authenticated ? (
          <IconButton
            data-cy="authToolbarButton"
            disableRipple
            onClick={handleOpenUserMenu}
            sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}
          >
            <Typography data-cy="AuthToolbarTypography" sx={{ mr: 2 }} noWrap>
              {keycloak.tokenParsed.given_name + " " + keycloak.tokenParsed.family_name}
            </Typography>
            <Avatar
              data-cy="AuthToolbarAvatarIcon"
              alt={keycloak.tokenParsed.given_name + " " + keycloak.tokenParsed.family_name}
              src="/broken-image.jpg"
            />
          </IconButton>
        ) : (
          <div
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            <IconButton
              data-cy="authToolbarButton"
              disableRipple
              onClick={() => keycloak.login()}
              sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}
            >
              <Typography data-cy="AuthToolbarTypography" sx={{ mr: 2 }}>
                Login
              </Typography>
              <Avatar data-cy="AuthToolbarAvatarIcon" src="/broken-image.jpg" />
            </IconButton>
          </div>
        )}
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        BackdropProps={{
          "data-cy": "userAuthMenuBackdrop",
          invisible: true,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 0.5rem 1rem lightgray)",
            mt: 1.5,
            borderRadius: 2,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem key="logout" sx={{ borderRadius: 2, mx: 1 }} onClick={handleLogOut}>
          <Typography textAlign="center">Log out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
