// Modules imports
import * as React from "react"
import PropTypes from "prop-types"
import { AppBar, Box, CssBaseline, IconButton, SvgIcon, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

// App components
import AuthToolbarItem from "./AuthToolbarItem"
import { ReactComponent as HomeIcon } from "../../images/HEIG-VD_logotype_rouge-rvb.svg"
import { Navigate } from "react-router-dom"

/**
 * Component Topbar
 *
 * HOC that displays topbar and children passed in props
 *
 * @param {*} props
 * -
 * - children : children to display
 *
 * @returns JSX.Element
 */
export default function Topbar({ children }) {
  const [open, setOpen] = React.useState(window.innerWidth >= 1000 ? true : false)

  const handleDrawer = () => {
    open ? setOpen(false) : setOpen(true)
  }

  return (
    <Box id="TopbarContainer" data-cy="root-router" sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="white"
        elevation={0}
        open={open}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: "2px whitesmoke dashed" }}
      >
        <Toolbar sx={{ display: "flex", ml: -2, justifyContent: "space-between", gap: 2 }}>
          <Box display="flex" alignItems="center">
            <IconButton color="inherit" id="sidebarToggleButton" onClick={handleDrawer}>
              <MenuIcon />
            </IconButton>
            <IconButton
              sx={{ py: 0 }}
              onClick={() => Navigate("/")}
              style={{ backgroundColor: "transparent" }}
            >
              <SvgIcon fontSize="large">
                <HomeIcon />
              </SvgIcon>
            </IconButton>
            <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", ml: 1 }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: "#333333",
                  lineHeight: 1.2,
                  textTransform: "uppercase",
                }}
                noWrap
              >
                Service réception
              </Typography>
              <Typography sx={{ fontSize: "0.90rem", color: "dimgray", lineHeight: 1 }} noWrap>
                Interface de gestion
              </Typography>
            </Box>
          </Box>
          <AuthToolbarItem />
        </Toolbar>
      </AppBar>
      {React.cloneElement(children, { open: open })}
    </Box>
  )
}

Topbar.propTypes = {
  children: PropTypes.element,
}
