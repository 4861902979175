import { ReactKeycloakProvider } from "@react-keycloak/web"
import KeycloakInit from "./KeycloakInit"
import keycloak from "./keycloak"

import { createTheme, ThemeProvider } from "@mui/material"
import { colors } from "@mui/material/"

const theme = createTheme({
  typography: {
    fontFamily: ["Arial"],
    allVariants: {
      color: "#333333"
    }
  },
  palette: {
    warning: {
      main: "#ffa000",
      contrastText: "#fff"
    },
    white: {
      main: "#ffffff"
    },
    shade: {
      main: "lightgray",
      bg: "whitesmoke"
    },
    primary: {
      main: colors.blue[500],
      bg: colors.blue[50]
    },
    heig: {
      main: "#e1251b",
      bg: "#FCE7E6",
      hover: "FEF3F3",
      contrastText: "#fff"
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.blue[500]
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "lightgray",
            borderWidth: 1
          },
          "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: colors.blue[500]
          },
          "&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: colors.blue[500]
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          gap: "24px",
          alignItems: "stretch"
        },
        content: {
          flex: 0,
        },
        action: {
          margin: 0,
          flex:1,
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // color: colors.blue[500],
          ".MuiTableSortLabel-icon": {
            opacity: 0,
            color: colors.blue[500]
          },
          "&:hover": {
            // color: colors.blue[500],
            "&.MuiTableSortLabel-icon": {
              opacity: 1,
              color: colors.blue[500]
            }
          },
          "&.Mui-active": {
            // color: colors.blue[500],
            ".MuiTableSortLabel-icon": {
              opacity: 1,
              color: colors.blue[500]
            }
          }
        }
      }
    }
  }
})

function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <ThemeProvider theme={theme}>
        <KeycloakInit />
      </ThemeProvider>
    </ReactKeycloakProvider>
  )
}

export default App
