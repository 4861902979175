import { useKeycloak } from "@react-keycloak/web"
import { Box, Button, Container, CssBaseline, Paper, Typography } from "@mui/material"
import { ReactComponent as HeigvdLogo } from "../images/HEIG-VD_logotype_rouge-rvb.svg"
import pkg from "../../package.json"

export default function NotLogged({ badUser = false }) {
  const { keycloak } = useKeycloak()

  return (
    <Container id="NotLoggedContainer" data-cy="root-router">
      <CssBaseline component="main" />
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 4,
          width: "50%",
          margin: "auto",
          marginTop: "10%",
          borderRadius: 2,
          filter: "drop-shadow(0 0rem 4mm lightgray)"
        }}
      >
        <Box id="test123" display="flex">
          <Box width={100} height={100}>
            <HeigvdLogo />
          </Box>
          <Box flex={1} ml={4}>
            <Typography variant="h5" sx={{ textTransform: "uppercase", lineHeight: 1 }}>
              Service réception
            </Typography>
            <Typography variant="h6">Interface de gestion</Typography>
            {badUser && <Typography variant="button" color={"error"} fontWeight={"bold"}>Access
              Denied</Typography>}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems={"center"}>
          <div />
          <Button
            variant={badUser ? "outlined" : "contained"}
            size="large"
            color="heig"
            style={{ marginTop: 3, borderRadius: 8 }}
            // sx={{"&:disabled": {color: "red", backgroundColor: "mistyrose"}}}
            onClick={badUser ? keycloak.logout : keycloak.login}
            // disabled={badUser}

          >
            {badUser ? "Se déconnecter" : "Se connecter"}
          </Button>

        </Box>
        <Box display="flex" justifyContent="right" mb={-2}>
          <Typography variant="button">{`Version : ${pkg.version}`}</Typography>
        </Box>
      </Paper>
    </Container>
  )
}
