import { TablePagination } from "@mui/material"

export default function TablePaginationSR({
  totalDocs,
  page,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
}) {
  const handleChangePage = (event, newPage) => {
    handlePageChange(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    handleRowsPerPageChange(event.target.value)
  }

  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 30]}
      colSpan={3}
      component={"div"}
      count={totalDocs}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          "aria-label": "Projets par page",
        },
        native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{ display: "flex", justifyContent: "flex-end" }}
    />
  )
}
