import OrdersCardHeader from "./OrdersCardHeader"
import { Card, CardActions, CardContent, Divider, Table, TableContainer } from "@mui/material"
import { cloneElement, useState } from "react"
import TableHeadSort from "./TableHeadSort"

export default function OrdersTableContainer({ children, paginationComponent, orders }) {
  const [searchInput, setSearchInput] = useState("")
  const [sortBy, setSortBy] = useState("orderedAt") // Sorting : column
  const [orderBy, setOrderBy] = useState("desc") // Sorting : asc/desc

  function searchInputFilter(order) {
    const cleanSearchInput = searchInput.trim().toLowerCase()
    if (order.number.toLowerCase().includes(cleanSearchInput)) return true
    if (order.applicant.toLowerCase().includes(cleanSearchInput)) return true
    if (order.supplierName.toLowerCase().includes(cleanSearchInput)) return true
    if (order.supplierAddress.toLowerCase().includes(cleanSearchInput)) return true
    return new Date(order.orderedAt)
      .toLocaleDateString("fr-CH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      .toLowerCase()
      .includes(cleanSearchInput)
  }

  const filteredOrders = orders.filter((order) => (searchInput ? searchInputFilter(order) : true))

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && orderBy === "asc"
    setOrderBy(isAsc ? "desc" : "asc")
    setSortBy(property)
  }

  return (
    <Card
      id="TableOrdersContainer"
      elevation={0}
      sx={{
        filter: "drop-shadow(0 0rem 1mm lightgray)",
        borderRadius: 2,
        minWidth: "840px",
        maxWidth: "1200px",
        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "70%" },
        mx: "auto",
        transition: "all 0.5s linear",
      }}
    >
      <OrdersCardHeader searchInput={searchInput} setSearchInput={setSearchInput} />
      {/* TABLE CONTAINER */}
      <CardContent sx={{ p: 3, py: 0 }}>
        <TableContainer>
          <Table
            sx={{
              tableLayout: "fixed",
              borderCollapse: "separate",
              borderSpacing: "0px 4px",
            }}
          >
            <TableHeadSort
              orderBy={orderBy}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
              headCells={[".details", "number", "applicant", "supplierName", "orderedAt"]}
            />
            {cloneElement(children, { orders: filteredOrders, orderBy, sortBy })}
          </Table>
        </TableContainer>
        <Divider sx={{ mt: 1 }} />
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
        {cloneElement(paginationComponent, { totalDocs: filteredOrders.length })}
      </CardActions>
    </Card>
  )
}
