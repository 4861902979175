// Modules imports
import { Home } from "@mui/icons-material"

import { Box, Toolbar, Typography } from "@mui/material"

/**
 * Component Home
 *
 * Home page of service reception app
 *
 * @returns JSX.Element
 */
export default function HomePage() {
  return (
    <Box id="HomePageContainer" display="flex" flexDirection="column" gap={2}>

      <Toolbar sx={{p:"0px!important"}}>
        <Home />
        <Typography ml={2} variant="h5">
          {"Accueil"}
        </Typography>
      </Toolbar>
      <Box display="flex" justifyContent="center" alignItems="center" margin="auto">
        <Typography textAlign="center" variant="h5" mr={2}>
          {"Bienvenue sur l'app"}
        </Typography>
        <Box display="flex" alignItems="center" bgcolor="#FCE7E6" p={2} borderRadius={2} gap={2}>
          <Typography textAlign="center" variant="h5" color="#e1251b">
            {"Service réception"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
