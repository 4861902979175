import { TableBody, TableCell, TableRow, Typography } from "@mui/material"

export default function EmptyTable() {

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={5} style={{ textAlign: "center" }}>
          <Typography>Aucun bon de commande à afficher.</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}