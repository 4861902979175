import { TableBody, TableCell, TableRow, Typography } from "@mui/material"

export default function ErrorBody() {

  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={5} style={{ textAlign: "center" }}>
          <Typography color="error">
            Une erreur est survenue. Essayez de recharger la page. Si le problème persiste, veuillez
            contacter le support.
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}