import * as React from "react"
import pkg from "../../../package.json"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Drawer, styled, Typography, Box, Toolbar } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Home, LocalShipping } from "@mui/icons-material"

// Requests imports
// import { useKeycloak } from "@react-keycloak/web";

// App components
import SidebarList from "./SidebarList"

// code for the drawer style
const drawerWidth = 260 // Same in Topbar component

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const miTheme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#FCE7E6",
            color: "#e1251b",

            "&:hover": {
              backgroundColor: "#FEF3F3",
            },
          },
          "&:hover": {
            color: "#e1251b",
            backgroundColor: "#FEF3F3",
          },
          borderRadius: 8,
        },
      },
    },
  },
})

const sidebarMenus = {
  Pages: {
    Accueil: { icon: <Home />, route: "/" },
    Commandes: { icon: <LocalShipping />, route: "/orders" },
  },
}

/**
 * Component Sidebar
 *
 * higher-order component that displays sidebar and child passed in props
 *
 * @param {*} props
 * -
 * - open: bool - sidebar display
 * - children: child to display
 *
 * @returns JSX.Element
 */
export default function Sidebar(props) {
  const { open, children } = props
  const navigate = useNavigate()
  // const { keycloak } = useKeycloak();
  const [selectedItem, setSelectedItem] = React.useState("")

  const handleClickItem = (index, route) => {
    setSelectedItem(index)
    navigate(route)
  }

  return (
    <>
      <Drawer
        data-cy="sidebar"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            border: " 2px whitesmoke dashed",
          },
          border: "none",
        }}
        variant="persistent"
        open={open}
      >
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            overflow: "auto",
          }}
        >
          <div>
            {/* public list */}
            <ThemeProvider theme={miTheme}>
              <SidebarList
                menus={sidebarMenus}
                selectedItem={selectedItem}
                handleClick={handleClickItem}
              />
            </ThemeProvider>
          </div>

          <Box sx={{ my: 2, textAlign: "center", color: "gray" }}>
            <Typography
              fontWeight={"bold"}
              color={"inherit"}
              variant="button"
              pb={2}
            >{`version : ${pkg.version}`}</Typography>
          </Box>
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </>
  )
}

Sidebar.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool,
}
