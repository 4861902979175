// Modules imports
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useKeycloak } from "@react-keycloak/web"

// App components
import Sidebar from "../components/navigation/Sidebar"
import Topbar from "../components/navigation/Topbar"
import OrdersPage from "../pages/OrdersPage"
import NotFound from "../pages/NotFound"
import HomePage from "../pages/HomePage"
import NotLogged from "../pages/NotLogged"

/**
 * Component Router
 *
 * Handle app routing
 */
export default function Router() {
  const { keycloak } = useKeycloak()

  const badUser = keycloak.authenticated && !keycloak?.tokenParsed?.access_resource?.["service-reception-app"]?.roles?.includes("user")

  if (!keycloak.authenticated || badUser) return <NotLogged badUser={badUser} />

    ///////////////////////// Component render /////////////////////////////
    return (
      <BrowserRouter>
        <Topbar>
          <Sidebar>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Sidebar>
        </Topbar>
      </BrowserRouter>
    )
}
