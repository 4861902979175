// Modules imports
import { Box, Toolbar, Typography } from "@mui/material"
import { LocalShipping } from "@mui/icons-material"

// App components
import OrdersTable from "../components/ordersTable/OrdersTable"

/**
 * Component OrdersFinder
 *
 * Page to find orders
 *
 * @returns JSX.Element
 */
export default function OrdersPage() {
  return (
    <Box id="OrdersPageContainer" display="flex" flexDirection={"column"} gap={2}>
      <Toolbar sx={{ p: "0px!important" }}>
        <LocalShipping />
        <Typography ml={2} variant="h5">
          Bons de commande
        </Typography>
      </Toolbar>
      <OrdersTable />
    </Box>
  )
}
