// Modules components
import * as React from "react"
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { visuallyHidden } from "@mui/utils"

const mapHeadcells = {
  number: "Numéro",
  applicant: "Requérant",
  supplierName: "Fournisseur",
  orderedAt: "Date de commande",
}

/**
 * Component EnhancedTableHead
 *
 * displays the head cells row of projects lists table
 *
 * @param {*} props
 * -
 * - orderBy : "asc" or "desc"
 * - sortBy : the column to sort on
 * - onRequestSort : callback function to sort the list
 * - headCells : label cells array
 *
 * @returns JSX.Element
 */
export default function TableHeadSort(props) {
  const { orderBy, sortBy, onRequestSort, headCells } = props

  // onRequestSort callback handler
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  ///////////////////////// Component render /////////////////////////////
  return (
    <TableHead>
      <TableRow
        sx={{
          "&> th": { backgroundColor: "whitesmoke" },
          "&> th:first-of-type": { borderRadius: `8px 0 0 8px` },
          "&> th:last-of-type": { borderRadius: `0 8px 8px 0` },
        }}
      >
        {headCells.map((headCell) => {
          return (
            <TableCell
              sx={{
                width: headCell[0] === "." ? "4rem" : "auto",
                fontWeight: "bold",
                wordWrap: "nowrap",
                fontSize: "1rem",
              }}
              key={"headCell" + headCell}
              align={"left"}
              sortDirection={sortBy === headCell ? orderBy : false}
            >
              {headCell[0] !== "." && (
                <TableSortLabel
                  active={sortBy === headCell}
                  direction={sortBy === headCell ? orderBy : "asc"}
                  onClick={createSortHandler(headCell)}
                >
                  {headCell[0] === "." ? " " : mapHeadcells[headCell]}
                  {sortBy === headCell ? (
                    <Box component="span" sx={visuallyHidden}>
                      {orderBy === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
