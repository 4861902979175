import { Box, Button, Collapse, TableCell, TableRow, Typography } from "@mui/material"
import { InsertDriveFileRounded } from "@mui/icons-material"
import { useKeycloak } from "@react-keycloak/web"
import apiClient from "../../services/axios.config"

export default function OrderDetailsRow({ order, index, openCollapse }) {
  const { keycloak } = useKeycloak()

  const onClick = () => {
    apiClient
      .get(`/purchase-orders/${order.id}`, {
        headers: { Authorization: "Bearer " + keycloak.token },
        responseType: "blob",
      })
      .then((res) => {
        window.open(URL.createObjectURL(res.data))
      })
  }

  return (
    <TableRow id={"orderDetails_" + order.number}>
      <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Collapse
          in={openCollapse[index]}
          sx={{ px: 4, py: 1, width: "100%" }}
          timeout={10}
          unmountOnExit
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Typography color="primary">{`Adresse du fournisseur : `}</Typography>
              <Typography data-cy={"TRD_supplierAddress"} ml={1}>
                {order.supplierAddress}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                endIcon={<InsertDriveFileRounded sx={{ ml: 1 }} />}
                onClick={onClick}
              >
                Bon de commande
              </Button>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
