// API requests configuration file
// baseURL => of the bureau-projet.api
import axios from "axios"

export default axios.create({
  baseURL: "https://po-dev-api.blacktree.io",
  headers: {
    "Content-type": "application/json",
  },
})
