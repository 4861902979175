function descendingComparator(a, b, sortBy) {
  if (b[sortBy] < a[sortBy]) {
    return -1
  }
  if (b[sortBy] > a[sortBy]) {
    return 1
  }
  return 0
}

export function getComparator(orderBy, sortBy) {
  return orderBy === "desc"
    ? (a, b) => descendingComparator(a, b, sortBy)
    : (a, b) => -descendingComparator(a, b, sortBy)
}
