import { Box, Button, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"

/**
 * Component NotFound
 *
 * display 404 page with go back to home button
 */
export default function NotFound() {
  let navigate = useNavigate()
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h2">404</Typography>
      <Typography>Page not found</Typography>
      <br />
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          navigate("/")
        }}
      >
        Go back
      </Button>
    </Box>
  )
}
