import { IconButton, Skeleton, TableBody, TableCell, TableRow } from "@mui/material"
import { KeyboardArrowRight } from "@mui/icons-material"

export default function LoadingBody() {

  return (
    <TableBody>
      <TableRow>
        <TableCell style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <IconButton size="small">{<KeyboardArrowRight />}</IconButton>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <IconButton size="small">{<KeyboardArrowRight />}</IconButton>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <IconButton size="small">{<KeyboardArrowRight />}</IconButton>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}